import axios from "axios";
import { API_URL } from "../../Helper";

export const INDEX_URL = API_URL+"events";
export const EVENTTYPES_URL = API_URL+"eventtypes";

export function index() {
  return axios.get(INDEX_URL);
}

export function getEventinfo(eventID) {
  return axios.get(INDEX_URL + "/" + eventID);
}

export function store(newevent){
  return axios.post(INDEX_URL, newevent);
}

export function patch(eventID, eventInfo){
  return axios.patch(INDEX_URL + '/' + eventID, eventInfo);
}

export function destroy(eventID){
  return axios.delete(INDEX_URL + '/' + eventID);
}

export function getEventtypes(){
  return axios.get(EVENTTYPES_URL);
}