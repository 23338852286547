import React from "react";
import { CircularProgress, Card } from "@material-ui/core";
import { CardBody } from "../../../../../_metronic/_partials/controls";

export default function LoadingComponent() {
  return (
    <Card>
      <CardBody className="splash-screen text-center">
        <h2>Carregando...</h2>
        <CircularProgress className="splash-screen-spinner text-primary" />
      </CardBody>
    </Card>
  );
}
