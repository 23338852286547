/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import LoadingComponent from "../../../../app/modules/GEE/pages/extra/LoadingComponent";
import NoPermissionComponent from "../../../../app/modules/GEE/pages/extra/NoPermissionComponent";
import { index } from "../../../../app/modules/GEE/crud/events.crud";

export function EventActivityWidget({ className }) {
  const [events, setEvents] = React.useState([]);
  const [section, setSection] = React.useState('loading');

  React.useEffect(() => {
    index().then(response => {
      setEvents(response.data.splice(0, 15));
      setSection('default');
    }
    ).catch(error => {
      switch (error.response.status) {
        case 401:
          setSection('noPermission');
          break;
        default:
          console.log(error);
          break;
      }
    });
  }, []);

  return (
    <>
      {section === 'loading' && <LoadingComponent />}
      {section === 'noPermission' && <NoPermissionComponent />}
      {section === 'default' &&
        <div className={`card card-custom ${className}`}>
          {/* Header */}
          <div className="card-header align-items-center border-0 mt-4">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">
                Atividade Recente nos Eventos
            </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                {events.length} Eventos no total
            </span>
            </h3>
          </div>
          {/* Body */}
          <div className="card-body pt-0">
            {events.map(event =>
              <div className="timeline timeline-5 mt-3" key={event.id}>
                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3 w-25">
                    {event.date_start}
                  </div>

                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-success icon-xxl"></i>
                  </div>

                  <div className="timeline-content text-dark-50">
                    {event.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
}
