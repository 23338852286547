export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => response,
    err => {
      const token_errors = [
        'Token is invalid',
        'Token is expired',
        'Authorization Token not found'
      ];
      if(token_errors.includes(err.response?.data[0])){
        window.location = "/logout";
      } else return Promise.reject(err)
    }
  );
}
