import React from "react";
import { Icon } from "@material-ui/core";
import { Card, CardBody, } from "../../../../../_metronic/_partials/controls";

export default function NoPermissionComponent() {
  return (
    <Card>
      <CardBody className="text-center">
        <Icon style={{ color: 'red' }} fontSize="large">block</Icon>
        <h2>Sem permissão</h2>
      </CardBody>
    </Card>
  );
}
